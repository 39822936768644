import React from 'react'
//import GlobalStyles from '../styles/GlobalStyle'
//import Typography from '../styles/Typography'
//import { motion, AnimatePresence } from 'framer-motion'

import Header from './Header';
import Footer from './Footer';
import Pagetransition from './Pagetransition';


 


export default function Layout({ children, location }) {

  return (
    <>
        <Header />
        <Pagetransition children={children} location={location} />
      <Footer />
    </>
  )
}