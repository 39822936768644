import React, {useEffect, useState} from 'react'
//import GlobalStyles from '../styles/GlobalStyle'
//import Typography from '../styles/Typography'
import { Link } from 'gatsby'
//import Headroom from "react-headroom"
//import NavMain from './NavMain';


export default function Header() {

  const [theme, setTheme] = useState('light');

  // The function that toggles between themes
  const toggleTheme = () => {
    // if the theme is not light, then set it to dark
    if (theme === 'light') {
      setTheme('dark');
    // otherwise, it should be light
    } else {
      setTheme('light');
    }
  }

  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark');
      document.body.classList.remove('light');
    } else {
      document.body.classList.remove('dark');
      document.body.classList.add('light');
    }
  }, [theme]); 

  return (
    <>

  
      <header className="header sticky-top_ py-5 h2">
        <div className="container-fluid  nav_ nav-fill_ d-flex justify-content-between align-items-center">

        <div className="brand  me-auto_">
          <Link to={'/'} className="nav-link_ h2">
            Fint
          </Link>
        </div>

        <Link to={'/projekte'} className="ms-3_ ms-md-6_ nav-link_">
          Projekte
        </Link>


        <button className="logo-icon btn"  onClick={toggleTheme}>
            <svg  xmlns="http://www.w3.org/2000/svg"   x="0px" y="0px" viewBox="00 0 189 130" width="60" className="img-fluid d-block mb-2">
              <title>Fint</title>
              <g id="mond">
    				    <circle stroke="#000" strokeWidth="3" fill="#fff" cx="48.9" cy="39.2" r="36.7"></circle>
    				  </g>
    				  <g id="berg">
    				    <path  fill="#000" d="M56.2,26.5l16,28L99.8,6.1l32.8,57.4L143,45.3l45.5,79.9H0L56.2,26.5z"></path>
    				  </g>
    				</svg>  
        </button>
            
     
          <Link to={'/angebot'} className="ms-3_ ms-md-6_ nav-link_">
          Angebot
        </Link>
        <Link to={'/ueber-uns'} className="ms-3_ ms-md-6_ nav-link_">
          Büro
        </Link>
        
        </div>

        <button className="button d-none" onClick={toggleTheme}>
          {theme === 'dark' ? (
            <>Light mode</>
          ) : (
            <>Dark mode</>
          )}
        </button>

        </header>

    </>
  )
}