import React from 'react'
import { Link } from 'gatsby'
import NavMain from './NavMain';
import Obfuscate from 'react-obfuscate';


export default function Footer() {

  const year = new Date().getFullYear();

  return (
    <>

  
      <footer className="container-fluid pt-10 pb-5 pt-md-20 pb-md-8  bg-black_ text-white_">
           
        <div className="h1 mb-10 mb-md-26">
        Fint Digital Design<br/>
        Zürich – Wien<br/>
        +41 34 223 23 22<br/>
        <Obfuscate email="mail@fint.ch" />
        </div>  
        <div className="row">
            
          <div className="col-12 col-md order-md-1 d-flex_ d-none ">
            <NavMain />
          </div>
            
          <div className="col-12 col-md    small">
              © {year} Fint Digital Design
            
          </div>

          <div className="col-12 col-md-auto  ms-md-auto  small">
           
            <Link to={'/impressum'} className="ms-3 ms-md-6 block-link_">
              Impressum
            </Link>  
         
            <Link to={'/datenschutz'} className="ms-3 ms-md-6 block-link_">
              Datenschutz
            </Link>  
          </div>

        
       
          

          </div>
       </footer>

    </>
  )
}