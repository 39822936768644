import React from 'react';
import Layout from './src/components/Layout';


// add Bootstrap
//import "bootstrap/dist/css/bootstrap.min.css"
import "./src/styles.scss";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

/*
const transitionDelay = 300;


export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};

*/
export const onInitialClientRender = () => {
  setTimeout(function() {
      document.getElementById("___loader").remove();
  }, 1)
}