import React from 'react'
import { Link } from 'gatsby'

export default function NavMain() {

  return (
    <>
    <nav className="ms-auto small">
     <Link to={'/journal'} className="ms-3 ms-md-6 block-link_">
          Journal
        </Link>
        <Link to={'/projekte'} className="ms-3 ms-md-6 block-link_">
          Projekte
        </Link>
        <Link to={'/angebot'} className="ms-3 ms-md-6 block-link_">
          Angebot
        </Link>
        <Link to={'/ueber-uns'} className="ms-3 ms-md-6 block-link_">
          Büro
        </Link>
      </nav>
    </>
  )
}