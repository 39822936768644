exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebot-js": () => import("./../../../src/pages/angebot.js" /* webpackChunkName: "component---src-pages-angebot-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-templates-wp-post-js": () => import("./../../../src/templates/WpPost.js" /* webpackChunkName: "component---src-templates-wp-post-js" */)
}

